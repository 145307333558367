@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

* {
  box-sizing: border-box;
}

.root {
  margin: 0;
  padding: 0;
}

/* 
  Theme 1: 
  https://coolors.co/e3d8f1-dabeca-bf8b85-ad8a64-5d5f71 
*/
$t1_main: #5d5f71;
$t1_second: #ad8a64;
$t1_third: #bf8b85;
$t1_fourth: #dabeca;
$t1_fifth: #e3d8f1;

/* 
  Theme 2:
  https://coolors.co/333232-f7b2ad-ceb7b3-b7b7b7-9abca7 
*/
$t2_main: #333232;
$t2_second: #f7b2ad;
$t2_third: #ceb7b3;
$t2_fourht: #b7b7b7;
$t2_fifth: #9abca7;

@mixin theme2_gradient {
  background: hsla(143, 20%, 67%, 1);

  background: linear-gradient(
    90deg,
    hsla(143, 20%, 67%, 1) 0%,
    hsla(0, 0%, 72%, 1) 34%,
    hsla(9, 22%, 75%, 1) 66%,
    hsla(4, 82%, 82%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(143, 20%, 67%, 1) 0%,
    hsla(0, 0%, 72%, 1) 34%,
    hsla(9, 22%, 75%, 1) 66%,
    hsla(4, 82%, 82%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(143, 20%, 67%, 1) 0%,
    hsla(0, 0%, 72%, 1) 34%,
    hsla(9, 22%, 75%, 1) 66%,
    hsla(4, 82%, 82%, 1) 100%
  );

  filter: 'progid: DXImageTransform.Microsoft.gradient( startColorstr=' #9abca7
    ', endColorstr=' #b7b7b7 ', GradientType=1 )';
}

@mixin theme2_text {
  ::-moz-selection {
    /* Code for Firefox */
    color: $t1_main;
    background: $t2_fourht;
  }

  ::selection {
    color: $t1_main;
    background: $t2_fourht;
  }

  color: $t2_main;
}

body {
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;

  // Theme 2
  @include theme2_gradient();
  @include theme2_text();
  // End Theme 2

  h1 {
    font-weight: bold;
    font-size: 21vw;
    margin: 0;
    background: linear-gradient(to bottom right, #ff8880, #74bd90);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width:768px) {
      font-size: 25vw;
    }
  }


  section {
    margin: 0 auto;
    width: 80%;

    header  {
      min-height: 100vh;
      padding: 0;
      @media screen and (max-width:768px) {
        margin: 20vh 0 0 0;
      }
    }

    article {

      @media screen and (max-width:768px) {
        &:first-of-type {
          margin-top: 0;
        }
      }
      
      width: 100vw;
      position: relative;
      left: calc(-50vw + 50%);
      background-color: rgba(255,255,255,.4);
      padding: 5vmin 15%;
      margin-top: 50vh;
      font-size: 4vmin;

      @media screen and (max-width:768px) {
        font-size: 7vw;
      }

      h2 {
        font-size: 5vw;
        margin: 0;
        @media screen and (max-width:768px) {
          font-size: 9vw;
        }
      }

      p {
        margin: 0 auto;
      }

      img {
        width: 100%;
      }

      ul {
        font-size: 3vmin;
        @media screen and (max-width:768px) {
          font-size: 5vw;
        }
      }

      .links {
        display: flex;
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
        svg {
          width: 50px;
          fill: black;
          &:hover {
            fill: white;
          }
        }
        div {
          cursor: pointer;
        }
      }
    }

    figure {
      width: 100vw;
      margin: 0;
      position: relative;
      left: calc(-50vw + 50%);
      p {
        font-size: 4vw;
        width: fit-content;
        margin: 0 auto;
        margin-top: calc(25vh - 4vw);
        @media screen and (max-width:768px) {
          font-size: 16vw;
          margin-top: calc(25vh - 16vw);
        }
      }
    }
  }
}
.no {
  position: absolute;
}

.hidden {
  display: none;
}

.box2 {
  grid-column-start: 1;
  grid-column-end: 3;
  @media screen and (max-width:768px) {
    grid-column-start: 1;
    grid-column-end: 6;
  }
}

.box4 {
  grid-column-start: 3;
  grid-column-end: 6;
  @media screen and (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 6;
  }
}

.box6 {
  grid-column-start: 1;
  grid-column-end: 6;
}

.grid6x1 {
  display: grid;
  grid-auto-flow: column;
  gap: 2vw;
  @media screen and (min-width: 0px) and (max-width:768px) {
    grid-template-rows: auto auto;
  }
}
