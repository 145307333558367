.progress {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 120px;
  height: 120px;
  z-index: 99999;
  @media screen and (max-width:768px) {
    width: 60px;
    height: 60px;
  }
}
